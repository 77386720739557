import Vue from 'vue'
import { DateTime } from 'luxon'
import { Api, GetData } from '@/model/api'
import { Week, Day, Occurrence } from '@/model/model'
import { createOccurrencesByWeek, createCancellationData } from '@/model/glue'


export const state = Vue.observable(new class State {

	public weeks: Week[] = [];
	public pendingCount: number = 0;

	private _data: GetData | undefined;
	private _pending: Occurrence[] | undefined;

	/*** Load ***/

	public async load(from: DateTime, to: DateTime): Promise<Week[]> {
		// Load data
		this._data = await Api.get();
		const mdl = createOccurrencesByWeek(this._data, 12);
		this.weeks = mdl.weeks;
		// Done
		return this.weeks;
	}

	/*** State ***/

	public toggle(occ: Occurrence) {
		occ.pending = !occ.pending;
		occ.cancelled = !occ.cancelled;
		toggleElement(this._pending ?? (this._pending = []), occ, occ.pending);
		this.pendingCount = this._pending.length;
	}

	public async save() {
		if (this._pending?.length) {
			await Api.set(
				createCancellationData(this._pending)
			);
			for (let occ of this._pending) {
				occ.pending = false;
			}
			this._pending = undefined;
			this.pendingCount = 0;
		}
	}

	public revert() {
		if (this._pending) {
			for (let occ of this._pending) {
				occ.pending = false;
				occ.cancelled = !occ.cancelled;
			}
			this._pending = undefined;
			this.pendingCount = 0;
		}
	}

});

/*** Utils ***/

function toggleElement<T>(arr: T[], el: T, state: boolean) {
	const i = arr.indexOf(el);
	if (state && i < 0)
		arr.push(el);
	if (!state && i >= 0)
		arr.splice(i, 1);
}
