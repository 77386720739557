<script>
import { state } from './state'

import WeeklyCalendar from '@/components/WeeklyCalendar.vue'
import PermViewDay from './PermViewDay.vue'
import AssigneeSelector from './AssigneeSelector.vue'
import AssigneeDisplay from './AssigneeDisplay.vue'
import ActionButtons from './ActionButtons.vue'

export default {

	components: { WeeklyCalendar, PermViewDay, AssigneeSelector, AssigneeDisplay, ActionButtons },

	data: () => ({
		state,
		loading: false,
		saved: false
	}),

	async mounted() {
		this.loading = true;
		await state.load();
		this.loading = false;
	}
}

</script>

<template>
	<div class="PermView" :class="{ 'has-modal': state.editing || saved }">
		
		<div class="header">
			<ul class="tabs">
				<li><router-link to="/">Perms</router-link></li>
				<li><router-link to="/admin">Annulations</router-link></li>
			</ul>
			<ActionButtons
				class="actions"
				v-if="state.selecting"
				:selectionCount="state.toggledOccurrences.length"
				@submit="state.goSave(), saved = true"
				@cancel="state.goView()"
			/>
			<AssigneeDisplay
				v-if="state.selecting"
				:assignee="state.currentAssignee"
				@click.native="state.goEdit()"
			/>
			<button class="register button primary" v-if="state.viewing && !saved" @click="state.goSelectWithCurrent()">
				S'inscrire <FaIcon icon="check-double"/>
			</button>
		</div>
		<div class="message">
			Bienvenue sur le planning des permanences où tu peux t'inscrire comme bénévole. Remarque : si tu coches "19h" cela signifie que tu t'engages à venir de 19h à 20h
Si tu as un empêchement, pas de soucis, tu te désinscris du planning et tu envoies un petit mail sur la <a href="mailto:permanence.sourisverte@framalistes.org">liste bénévole</a>, comme ça les autres bénévoles sont au courant, et peut-être que quelqu'un·e pourra venir te remplacer.
		</div>
		<WeeklyCalendar :weeks="state.weeks" v-slot="{ day }">
			<PermViewDay :day="day" @occurrenceSelected="state.toggleOccurrence($event)"/>
		</WeeklyCalendar>

		<AssigneeSelector
			class="selector"
			v-if="state.editing"
		/>

		<div class="notice" v-if="state.viewing && saved" @click="saved = false">
			<div class="hooray"> <FaIcon icon="laugh"/> Inscription validée !</div>
			<div>Si je ne peux pas venir, je n'oublie pas de me désinscrire et de prévenir mes camarades bénévoles.</div>
		</div>

		<div class="overlay" v-if="loading || saved || state.editing" @click="state.goView(), saved = false"/>

	</div>
</template>

<style scoped>

@media screen and (max-width: 540px) {
	.PermView {
		padding-bottom: 5rem;
	}
	.PermView.has-modal {
		max-height: 100vh;
		overflow: hidden;
	}
}

.header {
	position: sticky;
	top: 0;
	z-index: 100;
	margin-bottom: 1rem;
}

@media screen and (max-width: 540px) {
	.register {
		position: fixed;
		bottom: 1em;
		right: 1em;
		z-index: 200;
	}
}

.selector, .notice {
	position: fixed;
	z-index: 100;
	max-height: 100vh;
	padding: 1rem;
}
@media screen and (max-width: 540px) {
	.selector, .notice {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
@media screen and (min-width: 541px) {
	.selector, .notice {
		top: 0;
		right: 0;
		min-width: 20em;
		border-bottom-left-radius: 20px;
	}
	.notice {
		max-width: 30%;
	}
}

@media screen and (max-width: 540px) {
	.actions {
		position: fixed;
		z-index: 100;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1rem;
	}
}
@media screen and (min-width: 541px) {
	.actions {
		padding-right: 3vw;
		margin-right: 3vw;
		border-right: dashed 1px #333;
	}
}

/***/

.header {
	display: flex;
	align-items: center;
	padding: 0 1rem;
	color: #FFF;
	background: #000;
}
.header > .tabs {
	flex: 1;
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-size: 130%;
	color: #DDD;
}

.selector, .actions, .notice {
	background-color: #000;
	color: #FFF;
}

.register {
	box-shadow: 0px 0px 15px #000;
}
.register > svg {
	font-size: 120%;
	margin-left: .5em;
}

.notice {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}
.notice > .hooray {
	padding: 1em 0;
	font-size: 150%;
	text-decoration: underline var(--app-accent-color) 3px;
}
.notice > .hooray > svg {
	font-size: 160%;
	vertical-align: middle;
	color: var(--app-accent-color);
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 50;
}

/***/

.tabs {
	display: block;
}
.tabs > li {
	display: inline-block;
}

.tabs a {
	display: block;
	padding: .8em;
	border-bottom: solid 3px transparent;
}
.tabs a:hover, .tabs a:focus {
	border-color: #999;
}
.tabs a.router-link-exact-active {
	border-color: var(--app-accent-color);
	color: #FFF;
}

.message {
	margin: 1.2em 1em;
	padding: 1em 1em;
	color: #fff;
	border: solid 2px transparent;
	background: #555;
	border-radius: 5px;

	& a {
		text-decoration: underline;
	}
}

</style>
