import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { Settings } from 'luxon'

// Config
Vue.config.productionTip = false

// Debug
Vue.prototype.$log = (...args: any[]) => { console.log(...args); return args[0]; };

// Font Awesome
library.add(fas);
Vue.component('FaIcon', FontAwesomeIcon);

// Luxon
Settings.defaultLocale = 'fr-FR';

new Vue({
  router,
	render: h => h(App)
}).$mount('#app')
