import { DateTime } from 'luxon'


/******** API MODEL ********/

export type WeekDayValue = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface WeeklySlot {
	weekday: WeekDayValue
	hour: number
	minute: number
	assignees?: string[]
}

export interface WeeklyOccurrence {
	assignees?: {
		[name: string]: boolean
	}
	cancelled?: boolean
}

export interface GetData {
	slots: WeeklySlot[]
	occurrences: {
		[instant: string]: WeeklyOccurrence
	}
}

export interface SetData {
	assignees?: {
		[assignee: string]: {
			[instant: string]: boolean | null
		}
	},
	cancellations?: {
		[instant: string]: boolean
	}
}


/******** API SERVICE ********/

const API_CFG = 'api/conf.json';
const API_GET = 'api/data.json';
const API_SET = 'api/data.php';

export const Api = {

	async get(): Promise<GetData> {

		const [ config, data ] = await Promise.all([
			httpGetJson(API_CFG),
			httpGetJson(API_GET, {})
		]);

		return {
			slots: config.slots,
			occurrences: data.occurrences
		};
	},

	set(data: SetData): Promise<Response> {
		return httpPostJson(API_SET, data);
	},

	dt2str(instant: DateTime) {
		return instant.toISO({ suppressMilliseconds: true, includeOffset: false });
	},

	str2dt(str: string): DateTime {
		return DateTime.fromISO(str);
	}

};


/******** UTILS ********/

function httpGetJson(url: string, defaultValue?: any) {
	return fetch(url)
		.then(r => {
			if (Math.floor(r.status / 100) == 2) return r.json();
			if (defaultValue !== undefined && r.status == 404) return defaultValue;
			return Promise.reject(r);
		});
}

function httpPostJson(url: string, data: any) {
	return fetch(url, { method: 'POST', body: JSON.stringify(data) })
		.then(r => {
			if (Math.floor(r.status / 100) == 2) return r;
			return Promise.reject(r);
		});
}
