<script>
import autofocus from '@/components/autofocus'
import { Assignee } from '@/model/model'
import { state } from './state'

function simpleString(name) {
	return name && name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export default {

	directives: { autofocus },

	data: () => ({
		state,
		name: '',
		remember: state.remember
	}),

	computed: {

		matchingAssignees() {
			const name = simpleString(this.name);
			return this.state.assignees.filter(a => simpleString(a.name).includes(name));
		},

		nameExists() {
			const name = simpleString(this.name);
			return this.matchingAssignees.filter(a => simpleString(a.name) == name).length;
		}

	},

	methods: {
		select(ass) {
			this.state.goSelectWith(ass, this.remember);
		},
		create() {
			this.state.goSelectWith(new Assignee(this.name), this.remember);
		},
		cancel(ass) {
			this.state.goView();
		}
	}
}
</script>

<template>
	<div class="AssigneeSelector">

		<button class="cancel" @click="cancel()">
			<FaIcon icon="arrow-left"/>
		</button>

		<div class="list">
			<button class="item" v-for="ass in matchingAssignees" :key="ass.name" @click="select(ass)">
				<FaIcon icon="user"/> {{ ass.name }}
			</button>
			<button class="item" v-if="name && !nameExists" @click="create()">
				<FaIcon icon="user-plus"/> {{ name }} <small>(créer une nouvelle personne)</small>
			</button>
		</div>

		<div class="filter">
			<!-- No v-model to avoid issue with composition on mobile: https://github.com/vuejs/vue/issues/9777#issuecomment-478831263 -->
			<input
				class="input"
				v-autofocus
				:value="name"
				@input="name = $event.target.value"
				placeholder="Mon nom"
				spellcheck="false"
				autocomplete="off"/>
			<label class="remember">
				Se souvenir de mon nom <input type="checkbox" v-model="remember"/>
			</label>
		</div>

	</div>
</template>

<style scoped>

.AssigneeSelector {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	color: #EEE;
}
@media screen and (min-width: 541px) {
	.AssigneeSelector {
		flex-direction: column-reverse;
	}
	.cancel {
		display: none;
	}
}

.cancel {
	align-self: flex-start;
	margin-bottom: auto;
	font-size: 130%;
}

.list {
	flex: 0 1 auto;
	overflow-y: auto;
}

.item {
	display: block;
	width: 100%;
	padding: .5rem;
	text-align: left;
}
.item:not(:first-child) {
	border-top: solid 1px #333;
}
.item:hover, button:focus {
	background: var(--app-accent-color);
}
.item > svg {
	margin-right: .3em;
}

.input {
	width: 100%;
	margin: .5em 0;
}
.input::placeholder {
	font-style: italic;
	opacity: .4;
}

.remember {
	display: block;
	text-align: right;
	font-size: 70%;
	cursor: pointer;
}
.remember > input {
	vertical-align: middle;
	margin: 0;
	margin-left: .2em;
}

small {
	font-size: 80%;
	opacity: .6;
}

</style>
