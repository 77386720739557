<script>
import { DateTime } from 'luxon'
import { state } from './state'

import WeeklyCalendar from '@/components/WeeklyCalendar.vue'

export default {

	components: { WeeklyCalendar },

	filters: {
		format (instant, fmt) {
			return instant.toFormat(fmt).replace('.', '');
		}
	},

	data: () => ({
		state,
		loading: false
	}),

	async mounted() {
		this.loading = true;
		await state.load();
		this.loading = false;
	}
}

</script>

<template>
	<div class="AdminView">

		<div class="header">
			<ul class="tabs">
				<li><router-link to="/">Perms</router-link></li>
				<li><router-link to="/admin">Annulations</router-link></li>
			</ul>
			<button class="button secondary" :disabled="!state.pendingCount" @click="state.revert()">
				Annuler
			</button>
			<button class="button primary" :disabled="!state.pendingCount" @click="state.save()">
				Valider {{ state.pendingCount }} changements
			</button>
		</div>

		<WeeklyCalendar :weeks="state.weeks" v-slot="{ day }">
			<div class="day">
				<div class="date">
					<span>{{ day.instant | format('ccc') }}</span> {{ day.instant | format('dd LLL') }}
				</div>
				<div class="occ" v-for="occ in day.occurrences" :key="occ.index" :class="{ cancelled: occ.cancelled }" @click="state.toggle(occ)">
					<div class="occ-content" v-if="occ.instant.isValid">
						<div class="time">{{ occ.instant | format('HH') }}h</div>
						<FaIcon :icon="occ.cancelled ? 'times' : 'check'"/>
					</div>
				</div>
			</div>
		</WeeklyCalendar>

		<div class="overlay" v-if="loading"/>

	</div>
</template>

<style scoped>

.header {
	position: sticky;
	top: 0;
	z-index: 100;
	margin-bottom: 1rem;
}

/***/

.header {
	display: flex;
	align-items: center;
	padding: 0 1rem;
	color: #FFF;
	background: #000;
}
.header > .tabs {
	flex: 1;
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-size: 130%;
	color: #DDD;
}
.header > button {
	margin-left: .5em;
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 50;
}

/***/

.day {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: .5em 1em;
}
.date {
	flex: 2;
}
.occ {
	flex: 1;
	text-align: center;
	cursor: pointer;
}

/***/

.day {
	background-color: #444;
}

.date {
	color: #999;
}
.date > span {
	font-size: 120%;
	font-weight: 600;
}

.occ {
	color: #EEE;
}
.occ.cancelled {
	color: #F04747;
}

/***/

.tabs {
	display: block;
}
.tabs > li {
	display: inline-block;
}

.tabs a {
	display: block;
	padding: .8em;
	border-bottom: solid 3px transparent;
}
.tabs a:hover, .tabs a:focus {
	border-color: #999;
}
.tabs a.router-link-exact-active {
	border-color: var(--app-accent-color);
	color: #FFF;
}

</style>
