import { render, staticRenderFns } from "./AdminView.vue?vue&type=template&id=4ab0bccf&scoped=true&"
import script from "./AdminView.vue?vue&type=script&lang=js&"
export * from "./AdminView.vue?vue&type=script&lang=js&"
import style0 from "./AdminView.vue?vue&type=style&index=0&id=4ab0bccf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab0bccf",
  null
  
)

export default component.exports