<script>
import { state } from './state'

export default {

	props: ['day'],

	data: () => ({ state }),

	filters: {
		format (instant, fmt) {
			return instant.toFormat(fmt).replace('.', '');
		}
	},

	computed: {

		assignees() {
			return this.day.occurrences
				.filter(o => o.instant.isValid && !o.cancelled)
				.flatMap(o => o.assignees)
				.filter((a, i, arr) => arr.indexOf(a) === i)
				.sort((a0, a1) => a0.name > a1.name ? 1 : a0.name < a1.name ? -1 : 0);
		},

		occurrences() {
			return this.day.occurrences;
		},

		firstOccurrenceIndex() {
			for (let i = 0; i < this.occurrences.length; i++)
				if (this.occurrences[i].instant.isValid)
					return i;
		},

		lastOccurrenceIndex() {
			for (let i = this.occurrences.length - 1; i >= 0; i--)
				if (this.occurrences[i].instant.isValid)
					return i;
		},

		isAssigneePresent() {
			return this.assignees.find(a => a.selected);
		}
	},

	methods: {

		status(occ) {
			return occ.instant.isValid && !occ.cancelled && (occ.assignees.length > 2 ? 'good' : occ.assignees.length > 1 ? 'soso' : 'bad');
		},

		position(o) {
			return o == 0 ? 'first' : o == this.occurrences.length - 1 ? 'last' : null;
		},

		selectOcc(occ) {
			this.$emit('occurrenceSelected', occ);
		},

		selectDay() {
			this.$emit('daySelected', this.day);
		}
	}
}

</script>

<template>
	<div class="PermViewDay"
	     :class="state.selecting && 'selecting'">

		<div class="date">
			<span>{{ day.instant | format('ccc') }}</span> {{ day.instant | format('d LLL') }}
		</div>

		<div class="content"
		     :style="{ gridTemplate: `auto repeat(${assignees.length || 1}, auto) / minmax(6em, 2fr) repeat(${occurrences.length || 1}, minmax(2em, 1fr))` }">

			<template v-for="(occ, o) in occurrences">

				<div class="time"
				     v-if="occ.instant.isValid"
				     :key="o + 1000"
				     :style="{ gridColumn: o + 2 }">
					<template v-if="!occ.cancelled">
						{{ occ.instant.hour }}<span>h</span>
					</template>
					<template v-else>
						<FaIcon icon="times"/>
					</template>
				</div>

				<div class="occ bg"
				     :key="o + 1100"
				     :class="[ status(occ), position(o), { selected: occ.selected, pending: occ.pending, cancelled: occ.cancelled } ]"
				     :style="{ gridColumn: o + 2 }">
				</div>

				<button class="occ fg"
				        v-if="state.selecting && occ.instant.isValid && !occ.cancelled"
				       :key="o + 1200"
				       :class="[ position(o), { pending: occ.pending } ]"
				       :style="{ gridColumn: o + 2 }"
				       @click="selectOcc(occ)">
					<template v-if="!isAssigneePresent">
						<FaIcon icon="circle" class="plus bg" :class="status(occ)"/>
						<FaIcon icon="plus-circle" class="plus"/>
					</template>
				</button>

			</template>

			<template v-for="(ass, a) in assignees">

				<div class="name"
				     :key="a + 300"
				     :class="ass.selected && 'selected'"
				     :style="{ gridRow: a + 2 }">
					<span>{{ ass.name }}</span>
				</div>

				<div class="ass"
				     :key="a + 400"
				     :class="ass.selected && 'selected'"
				     :style="{ gridRow: a + 2 }">
				</div>

				<div class="check"
				     v-for="(occ, o) in occurrences"
				     v-if="occ.assignees.includes(ass) && !occ.cancelled"
				     :key="a + 500 + o * 10"
				     :class="ass.selected && 'selected'"
				     :style="{ gridColumn: o + 2, gridRow: a + 2 }">
					<FaIcon icon="check-circle"/>
				</div>

			</template>
<!--
			<div class="all"
			     v-if="state.selecting && !isAssigneePresent"
					 :style="{ gridColumn: `${firstOccurrenceIndex + 2} / ${lastOccurrenceIndex + 3}` }">
				<FaIcon icon="plus-circle"/>
			</div>
-->
		</div>
	</div>
</template>

<style scoped>

.PermViewDay {
	position: relative;
}
.date {
	position: absolute;
	top: .2em;
	left: .6em;
}
.date > span {
	font-weight: 700;
}

.content {
	display: grid;
}
.time {
	grid-row-start: 1;
}
.name {
	grid-column-start: 1;
}
.occ {
	grid-row: 1 / -1;
}
.ass {
	grid-column: 1 / -1;
}
.occ.bg {
	z-index: 1;
}
.occ.fg {
	z-index: 3;
}
.ass {
	z-index: 2;
}
.time, .name, .check {
	z-index: 2;
}
.name, .check {
	align-self: center;
}
.time, .check {
	text-align: center;
}
.name {
	text-align: right;
}

.PermViewDay {
	padding: 1em 0;
}

.content {
	padding-bottom: 5px;
}
.time, .name, .occ, .check {
	margin-right: 16%;
}
.occ {
	margin-bottom: -3px;
	border-radius: 3px;
}

/***/

.time {
	padding: .3em .4em;
	line-height: 1rem;
	font-size: 90%;
	font-weight: 600;
}
.time > span {
	font-size: 80%;
	font-weight: 400;
}

.name {
	padding: .3em 0;
}
.name > span {
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.name, .check {
	line-height: 1rem;
	font-size: 70%;
}

.ass {
	border-top: solid 1px rgba(255,255,255,.1);
}
.ass.selected {
	border: none;
	background-color: rgba(255,255,255,.1);
}

.occ.bg {
	border: solid 1px transparent;
}

.occ.fg {
	position: relative;
	border: solid 2px;
	box-shadow: 0 0 10px #000;
	cursor: pointer;
}
.occ.fg > .plus {
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translate(-50%, -40%);
}
.occ.fg > .plus.bg {
	font-size: 90%;
}

.occ.bg.pending, .occ.fg.pending {
	border-color: var(--app-accent-color);
}

.selecting .name, .selecting .check {
	opacity: .3;
}
.selecting .name.selected, .selecting .check.selected {
	opacity: 1;
}

.check.selected, .occ.fg {
	font-size: 110%;
}

/***/

.PermViewDay {
	background: #444;
}

.date {
	color: #999;
}
.time {
	color: #555;
}
.name {
	color: #EEE;
}
.check, .occ.fg {
	color: #EEE;
}

.PermViewDay {
	--bad: #F04747;
	--soso: #E8BE14;
	--good: #75AD57;
}

.occ.bg {
	background: #4A4A4A;
}
.cancelled.occ.bg {
	background: #333;
}
.bad.occ.bg {
	background: var(--bad);
}
.soso.occ.bg {
	background: var(--soso);
}
.good.occ.bg {
	background: var(--good);
}

.bad.plus.bg {
	color: var(--bad);
}
.soso.plus.bg {
	color: var(--soso);
}
.good.plus.bg {
	color: var(--good);
}

</style>
