<script>

export default {

	props: ['assignee']

}
</script>

<template>
	<button class="AssigneeDisplay">

		<div class="assignee" v-if="assignee">
			<div class="name">{{ assignee.name }}</div>
			<div class="link">changer</div>
		</div>
		<div class="icon"><FaIcon icon="user"/></div>

	</button>
</template>

<style scoped>

	.AssigneeDisplay {
		display: flex;
		align-items: center;
	}

	.assignee {
		margin-right: .8em;
		text-align: right;
	}

	.icon {
		font-size: 200%;
	}

	.link {
		font-size: 70%;
		color: var(--app-accent-color);
	}
	.AssigneeDisplay:hover .link, .AssigneeDisplay:focus .link, .AssigneeDisplay:active .link {
		text-decoration: underline;
	}

</style>
