import { DateTime } from 'luxon'

export class HasInstant {
	constructor (public readonly instant: DateTime) {}
	valueOf() { return this.instant.valueOf() }
}

export class Assignee {

	public selected: boolean = false;

	constructor(public readonly name: string) {}
}

export class Occurrence extends HasInstant {

	public assignees: Assignee[] = [];
	public cancelled: boolean = false;
	public selected: boolean = false;
	public pending: boolean = false;

	constructor (instant: DateTime) {
		super(instant);
	}
}

export class Day extends HasInstant {

	public readonly occurrences: Occurrence[] = [];

	constructor (instant: DateTime) {
		super(instant.startOf('day'));
	}

	hasSame(instant: DateTime) {
		return this.instant.hasSame(instant, 'day');
	}

}

export class Week extends HasInstant {

	public readonly days: Day[] = [];

	constructor (instant: DateTime) {
		super(instant.startOf('week'));
	}

	hasSame(instant: DateTime) {
		return this.instant.hasSame(instant, 'week');
	}
}

export class Month extends HasInstant {

	public readonly weeks: Week[] = [];

	constructor (instant: DateTime) {
		super(instant.startOf('month'));
	}

	hasSame(instant: DateTime) {
		return this.instant.hasSame(instant, 'year') && this.instant.hasSame(instant, 'month');
	}
}

