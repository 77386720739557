<script>

export default {

	props: ['weeks'],

	methods: {
		exists(day) {
			return !!day.instant.isValid;
		},
		click(occ) {
			this.$emit('click', occ);
		}
	}
}

</script>

<template>
	<div class="WeeklyCalendar">

		<div class="week" v-for="(week, w) in weeks" :key="w">
			<div class="week-label">Semaine {{ week.instant.toFormat('WW') }}</div>
			<div class="week-content">
				<div class="day" v-for="(day, d) in week.days" :key="d" :class="{ ghost: !exists(day) }">
					<slot :day="day" v-if="exists(day)"/>
				</div>
			</div>
		</div>

	</div>
</template>

<style scoped>

@media screen and (min-width: 541px) {

	.WeeklyCalendar {
		display: flex;
		flex-flow: row wrap;
		padding: 1rem;
		padding-top: 0;
		align-items: stretch;
	}
	.week {
		flex: 1 1 51%;
		margin: 7%;
	}
	.week-content {
		border-radius: 5px;
		overflow: hidden;
	}

	.week, .week-content, .day {
		display: flex;
		flex-flow: column nowrap;
	}
	.week-content, .day, .day > * {
		flex: 1 0 auto;
	}

}
@media screen and (min-width: 769px) {
	.week {
			flex: 1 1 34%;
			margin: 3%;
	}
}
@media screen and (min-width: 1281px) {
	.week {
			flex: 1 1 21%;
			margin: .5%;
	}
}

.week-label {
	padding: .3em .6em;
	text-align: right;
	font-size: 70%;
	color: #777;
}
.week {
	margin-top: 1em;
	margin-bottom: 1em;
}

.day:not(:last-child) {
	margin-bottom: 2px;
}
.day.ghost {
	display: none;
}


</style>
